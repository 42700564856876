import { Component } from "react"
import React from "react";
import InitializePage from '../lib/server/page'
import Header from "../components/Layouts/Header";
import HomeFooter from '../components/Layouts/HomeFooter.jsx';
import SubscribeNewsLetter from '../components/common/subscribenewsletter.jsx';



async function loadData(context){                      
    return {};
}

export async function getStaticProps(context) {    
    return InitializePage(context,loadData, true)
}

const ErrorPage = ({menu, webTexts}) => {
    return (
    <>
        <Header page={'common'} menuSections={menu}/>
        <div className="container main-container static page404 mt-50">
            <div className="row">
                <div className="col-12 text-center">
                <div className="img404" >            
                    <img src="https://content.eccediciones.com/web-assets/error_web.jpg" />            
                </div>

                <h2 className="A-la-venta-esta-sema">NO ENCONTRADA</h2>
                <p>Lo sentimos, la página que estás buscando no se encuentra en nuestro directorio.</p>
                <a href="/" className="btn404">Ir a la Home</a>            
                </div>
            </div>
        </div>
        <div className="container line-container">
            <div className="line6"></div>
        </div>
        <SubscribeNewsLetter webTexts={webTexts}/>
        <HomeFooter />
    </>
    )
}
export default ErrorPage
